<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{
              name: 'OrganizationHome',
            }">
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{
              name: 'MarketingRuleList',
            }">行銷規則列表
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{
              isReadOnly ? "查看行銷規則" : isEditing ? "編輯行銷規則" : "新增行銷規則"
            }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看行銷規則" : isEditing ? "編輯行銷規則" : "新增行銷規則"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert v-if="validationErrors" :errors="validationErrors"></validation-error-alert>

                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="通路 id">
                  <b-form-select :disabled="isReadOnly" v-model="merchantId" :options="merchants"></b-form-select>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="關鍵字">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="rule.keyword"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="比對類型（完全比對、部分比對)">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="rule.matched_type"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="順序">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="rule.order"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="比對代碼">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="rule.matched_code"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="轉換比例">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="rule.convert_ratio"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button class="mr-3" @click="handleCancel" variant="outline-danger">返回
              </b-button>
              <b-button v-if="!isReadOnly" @click="handleSubmit" variant="success">儲存</b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import marketingRuleApi from "../../../apis/marketing-rule";
import merchantApi from "../../../apis/merchant";

export default {
  data() {
    return {
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      merchantId: null,
      merchants: [{ value: null, text: "請選擇" }],
      rule: {
        keyword: null,
        matched_type: null,
        order: 0,
        matched_code: null,
        convert_ratio: 0,
      },
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    isReadOnly() {
      return this.$route.name === "MarketingRuleDetail";
    },
    marketingRuleId() {
      return this.$route.params.marketingRuleId;
    }
  },
  mounted() {
    if (!this.marketingRuleId) {
      this.clearMarketingRule();
      this.isEditing = false;
    } else {
      this.fetchMarketingRule();
      this.isEditing = true;
    }
    this.fetchMerchants();
  },
  methods: {
    async fetchMarketingRule() {
      this.showLoading = true;
      const response = await marketingRuleApi.getMarketingRule(
        this.marketingRuleId
      );
      this.rule = response.data.data;
      this.merchantId = response.data.data.merchant_id;
      this.showLoading = false;
    },
    clearMarketingRule() {
      this.rule = {
        keyword: null,
        matched_type: null,
        order: 0,
        matched_code: null,
        convert_ratio: 0,
      };
    },
    async handleSubmit() {
      try {
        if (this.isEditing) {
          let response = await marketingRuleApi.updateMarketingRule(
            this.merchantId,
            this.marketingRuleId,
            this.rule
          );
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.$router.push({ name: "MarketingRuleList" });
          }
        } else {
          let response = await marketingRuleApi.createMarketingRule(this.merchantId, this.rule);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.$router.push({ name: "MarketingRuleList" });
          }
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    handleCancel() {
      this.$router.push({ name: "MarketingRuleList" });
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];
    },
  },
};
</script>
